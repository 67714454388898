import type { GiftEditorApi, GiftEditorApiOptions, Yimp } from '@yoursurprise/gift-editor';
import { window } from '../../../js/globals';
import type { ProductState } from '../../productInformation/Store/Product/state';
import GiftEditorApiProxy from '../Proxy/GiftEditorApiProxy';
import { getGiftEditorApiOptions } from './GiftEditorApiOptionsFactory';
import type { CartState } from '../../productInformation/Store/Cart/state';

const giftEditorApiProxy = new GiftEditorApiProxy();

export const getPreviewGiftEditorApi = async (product: Pick<ProductState, 'productId' | 'variantId'>, cart: CartState, yimp: Yimp | null): Promise<GiftEditorApi> => {
    const yimpCache = yimp ? JSON.stringify(yimp) : '';
    const cacheProperties = {
        editHash: cart.editHash,
        isCartEdit: cart.isCartEdit,
        productId: product.productId,
        uniqueId: cart.uniqueId,
        variantId: product.variantId,
    };
    const previewCacheProperties = { ...cacheProperties, yimpCache };

    const proxyInstance = giftEditorApiProxy.getCachedInstance(previewCacheProperties);

    if (proxyInstance) {
        return proxyInstance;
    }

    const shouldOverrideYimp = giftEditorApiProxy.getCacheProperties()?.productId === undefined || giftEditorApiProxy.getCacheProperties()?.productId === product.productId;

    const createNewInstance = async (): Promise<GiftEditorApi> => {
        const existingPersonalisation = (await giftEditorApiProxy.getInstance())?.getExistingPersonalisation();
        const apiOptions = await getGiftEditorApiOptions(cacheProperties, cart, existingPersonalisation);
        const options: GiftEditorApiOptions = { ...apiOptions, previewMode: true };

        if (shouldOverrideYimp && yimp) {
            options.productData.layoutArea.jYimp = yimp;
        }

        const { GiftEditorApiFactory } = await import('@yoursurprise/gift-editor');

        return new GiftEditorApiFactory((window as Window).siteMetadata.siteUrl).createFromApiOptions(options);
    };

    const newInstance = createNewInstance();

    giftEditorApiProxy.setInstance(previewCacheProperties, newInstance);

    return newInstance;
};
