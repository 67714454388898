export enum EditorType {
    ADVANCED_EDITOR = 'ADVANCED_EDITOR',
    QUICK_EDITOR = 'QUICK_EDITOR',
    REACT_EDITOR = 'REACT_EDITOR',
}

export enum Version {
    A = 'A',
    B = 'B',
}

interface MetaDataState {
    cdnUrl: string;
    editorType: EditorType | undefined | null;
    galleryAbTestVersion: Version;
    language: string;
    locale: string;
    partnerId: number;
    productIsAvailableForDesignSelectionAbTest: boolean;
    showShoppingAward: boolean;
    showUnpublished: boolean;
    siteUrl: string;
}

const initialMetaDataState: MetaDataState = {
    cdnUrl: '',
    editorType: undefined,
    galleryAbTestVersion: Version.A,
    language: 'EN',
    locale: 'nl-NL',
    partnerId: 0,
    productIsAvailableForDesignSelectionAbTest: false,
    showShoppingAward: false,
    showUnpublished: false,
    siteUrl: '',
};

export type { MetaDataState };
export default initialMetaDataState;
