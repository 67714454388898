import { useCallback } from 'react';
import { document, window } from '../../../js/globals';
import { quickEditorShown, reactEditorHidden, reactEditorShown } from '../Store/UI/action';
import { useTypedDispatch, useTypedSelector } from '../Store/connectors';

interface UseShowReactEditor {
    closeOnPageEditor: () => void;
    openOnPageEditor: () => void;
    shouldShowQuickEditor: boolean;
    shouldShowReactEditor: boolean;
}

export const useShowOnPageEditor = (): UseShowReactEditor => {
    const reactEditorActive = useTypedSelector(({ product }) => product.reactEditorActive);
    const quickEditorActive = useTypedSelector(({ product }) => product.quickEditorActive);
    const shouldShowReactEditor = useTypedSelector(({ ui }) => ui.shouldShowReactEditor);
    const shouldShowQuickEditor = useTypedSelector(({ ui }) => ui.shouldShowQuickEditor);

    const dispatch = useTypedDispatch();

    const openOnPageEditor = useCallback(() => {
        if (reactEditorActive) {
            if (window && document) {
                window.history.pushState({ showReactEditor: true }, document.title, window.location.href);
            }
            dispatch(reactEditorShown());
        }

        if (quickEditorActive && !reactEditorActive) {
            if (window && document) {
                window.history.pushState({ showQuickEditor: true }, document.title, window.location.href);
            }
            dispatch(quickEditorShown());
        }
    }, [dispatch, reactEditorActive, quickEditorActive]);

    const closeOnPageEditor = () => {
        if (reactEditorActive) dispatch(reactEditorHidden());

        if (quickEditorActive && !reactEditorActive) {
            window?.history.back();
        }
    };

    return {
        closeOnPageEditor,
        openOnPageEditor,
        shouldShowQuickEditor,
        shouldShowReactEditor,
    };
};
