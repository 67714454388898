export enum PrimaryCtaButton {
    AddToCart = 'AddToCart',
    StartPersonalising = 'StartPersonalising',
}

interface UiState {
    allowPersonalisationWarning: boolean;
    personalisationText: string;
    primaryCtaButton: PrimaryCtaButton;
    scrollY: number | undefined;
    shouldShowQuickEditor: boolean;
    shouldShowReactEditor: boolean;
    showStickyFooterButton: boolean;
}

const initialUiState: UiState = {
    allowPersonalisationWarning: false,
    personalisationText: '',
    primaryCtaButton: PrimaryCtaButton.StartPersonalising,
    scrollY: undefined,
    shouldShowQuickEditor: false,
    shouldShowReactEditor: false,
    showStickyFooterButton: false,
};

export type { UiState };
export default initialUiState;
