import { window } from '../../../../js/globals';
import { resumeScrollingActivity, suspendScrollingActivity } from '../../Util/Scroll';
import type { TypedMiddleware } from '../store';
import { quickEditorHidden, quickEditorShown, reactEditorHidden, reactEditorShown, scrollingActivityAllowed, scrollingActivityBlocked } from '../UI/action';

export const scrollActivityMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    next(action);

    const lastScrollY = storeApi.getState().ui.scrollY;

    if (quickEditorShown.match(action)) {
        storeApi.dispatch(scrollingActivityBlocked());
    }

    if (quickEditorHidden.match(action)) {
        storeApi.dispatch(scrollingActivityAllowed());

        // Since some mobile browsers make it impossible to block scrolling, reset the scroll position when the editor closes
        if (lastScrollY !== undefined) {
            window?.scrollTo(0, lastScrollY);
        }
    }

    if (reactEditorShown.match(action)) {
        storeApi.dispatch(scrollingActivityBlocked());
    }
    if (reactEditorHidden.match(action)) {
        storeApi.dispatch(scrollingActivityAllowed());
    }

    if (scrollingActivityBlocked.match(action)) {
        suspendScrollingActivity(lastScrollY);
    }

    if (scrollingActivityAllowed.match(action)) {
        resumeScrollingActivity();
    }
};
