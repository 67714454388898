import { useEffect } from 'react';
import { document, window } from '../../../js/globals';
import { quickEditorHidden, quickEditorShown } from '../Store/UI/action';
import { useTypedDispatch, useTypedSelector } from '../Store/connectors';

export interface QuickEditorPopStateEvent extends PopStateEvent {
    readonly state: { showQuickEditor: true } | null;
}

export const useMobileQuickEditorHistoryListener = (): void => {
    const shouldShowQuickEditor = useTypedSelector(({ ui }) => ui.shouldShowQuickEditor);

    const dispatch = useTypedDispatch();

    useEffect(() => {
        if (window && document) {
            window.history.replaceState({ showQuickEditor: shouldShowQuickEditor }, document.title, window.location.href);
        }
    }, [shouldShowQuickEditor]);

    useEffect(() => {
        const handleHistoryChange = ({ state }: QuickEditorPopStateEvent) => {
            const show = Boolean(state?.showQuickEditor);

            if (show) {
                dispatch(quickEditorShown());
            } else {
                dispatch(quickEditorHidden());
            }
        };

        if (window && document) {
            window.addEventListener('popstate', handleHistoryChange);
        }

        return () => window?.removeEventListener('popstate', handleHistoryChange);
    }, [dispatch]);
};
