interface CartState {
    editHash: string | null;
    isCartEdit: boolean;
    uniqueId: string | null;
}

const initialCartState: CartState = {
    editHash: null,
    isCartEdit: false,
    uniqueId: null,
};

export type { CartState };
export default initialCartState;
