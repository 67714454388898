import { editorTypeSet } from '../MetaData/action';
import { EditorType } from '../MetaData/state';
import type { TypedMiddleware } from '../store';
import { quickEditorShown, reactEditorShown } from '../UI/action';

export const openPageEditorMiddleware: TypedMiddleware = (storeApi) => (next) => (action): void => {
    if (editorTypeSet.match(action) && action.payload === EditorType.QUICK_EDITOR) {
        if (storeApi.getState().cart.isCartEdit) {
            storeApi.dispatch(quickEditorShown());
        }
    }

    if (editorTypeSet.match(action) && action.payload === EditorType.REACT_EDITOR) {
        if (storeApi.getState().cart.isCartEdit) {
            storeApi.dispatch(reactEditorShown());
        }
    }

    next(action);
};
