import type React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import useEditorType from '../../Hooks/UseEditorType';
import { useMobileQuickEditorHistoryListener } from '../../Hooks/UseMobileQuickEditorHistoryListener';
import Delivery from '../Delivery/Delivery';
import CombinedArticle from './CombinedArticle/CombinedArticle';
import CtaButtons from './CtaButtons/CtaButtons';
import MobileQuickEditorOverlay from './MobileQuickEditorOverlay/MobileQuickEditorOverlay';
import PriceBlock from './PriceBlock/PriceBlock';
import Rating from './Rating/Rating';
import SizeChart from './SizeChart/SizeChart';
import TemperatureWarning from '../TemperatureWarning/TemperatureWarning';
import { Version } from '../../Store/MetaData/state';
import ShippingMethods from '../ShippingMethods/ShippingMethods';
import ShoppingAward from '../../../general/ShoppingAward/ShoppingAward';
import { useShowOnPageEditor } from '../../Hooks/useShowOnPageEditor';
import styles from './InformationBlock.module.scss';
import CoolerNotification from '../CoolerNotification/CoolerNotification';
import { useTypedSelector } from '../../Store/connectors';

const InformationBlock: React.FC = () => {
    const hasPaidCoolerNotification = useTypedSelector(({ shipping }) => shipping.hasPaidCoolerNotification);
    const hasFreeCoolerNotification = useTypedSelector(({ shipping }) => shipping.hasFreeCoolerNotification);
    const hasTemperatureNotification = useTypedSelector(({ shipping }) => shipping.hasTemperatureNotification);
    const isSoldOut = useTypedSelector(({ product }) => product.isSoldOut);
    const productSubtitle = useTypedSelector(({ product }) => product.productSubtitle);
    const productTitle = useTypedSelector(({ product }) => product.productTitle);
    const isCartEdit = useTypedSelector(({ cart }) => cart.isCartEdit);
    const showShoppingAward = useTypedSelector(({ meta }) => meta.showShoppingAward);
    const productIsAvailableForDesignSelectionAbTest = useTypedSelector(({ meta }) => meta.productIsAvailableForDesignSelectionAbTest);
    const version = useTypedSelector(({ meta }) => meta.galleryAbTestVersion);
    const { isQuickEditorProduct } = useEditorType();
    const [showAddToCartButton, setShowAddToCartButton] = useState<boolean>(isCartEdit);

    const { closeOnPageEditor, openOnPageEditor, shouldShowQuickEditor } = useShowOnPageEditor();

    useMobileQuickEditorHistoryListener();

    const onCloseQuickEditorOverlayRequest = () => {
        closeOnPageEditor();
        setShowAddToCartButton(true);
    };

    return (
        <div data-testid='mainInformationBlock' className={classNames(styles.InformationBlock, 'product-information__content', {
            'design-test': productIsAvailableForDesignSelectionAbTest && version === Version.B,
        })}>
            <div className="product-information-desktop">

                <div data-testid='informationBlockTitles' className={classNames(styles.InformationBlock_titles, {
                    'design-test': productIsAvailableForDesignSelectionAbTest && version === Version.B,
                })}>
                    <h1 className="h3">{productTitle}</h1>
                    {productSubtitle && <h2 className={styles.InformationBlock_titles__subtitle}>{productSubtitle}</h2>}
                    <Rating/>
                    <Delivery/>
                    {(hasPaidCoolerNotification || hasFreeCoolerNotification) && <CoolerNotification isFree={hasFreeCoolerNotification}/>}
                </div>
                <div className="priceDetailsWrapper">
                    <CombinedArticle/>
                    {!isSoldOut && (
                        <div id="editor-widget-container">
                            {isQuickEditorProduct && (
                                <MobileQuickEditorOverlay
                                    onClose={onCloseQuickEditorOverlayRequest}
                                    showQuickEditor={shouldShowQuickEditor}
                                />
                            )}
                        </div>
                    )}
                    <SizeChart/>
                    <PriceBlock isSoldOut={isSoldOut}/>
                    {!isSoldOut && (
                        <CtaButtons
                            isCartEdit={isCartEdit}
                            onStartPersonalising={openOnPageEditor}
                            showAddToCartButton={showAddToCartButton}
                        />
                    )}
                    {(hasTemperatureNotification && !(hasFreeCoolerNotification)) && <TemperatureWarning/>}
                </div>
            </div>
            <ShippingMethods/>
            {showShoppingAward && <ShoppingAward showOnMobile={false} showOnDesktop/>}
        </div>
    );
};

export default InformationBlock;
